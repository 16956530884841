<template>
  <div>
    <leaflet-panel
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :show-detail-btn="showDetailBtn"
        :title="title"
    />
<!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`安全风险`"
               :en_title="`Security Risk`"></Paragraph>

   <Logotitlebox :datalist="datalistx"></Logotitlebox>
    <Logotitlebox :datalist="datalistx1"></Logotitlebox>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`解决方案`"
               :en_title="`Solution`"></Paragraph>

    <Logotitlebox :datalist="datalistx2"></Logotitlebox>
    <Logotitlebox :datalist="datalistx3"></Logotitlebox>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/star.svg`)"
               :title="`服务优势`"
               :en_title="`Service Advantage`"></Paragraph>

    <Titlestrlist :datalist="datalist" style="margin-bottom: 6rem"></Titlestrlist>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Logotitlebox from '@/components/chain/basic/common/logotitlebox';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Logotitlebox,
    Titlestrlist,
  },
  data() {
    return {
      datalist: [
        { title: '> ', content: '预研过百余种智能合约应用，熟悉各种智能合约的代码编写规律，能够精准发现代码级别漏洞' },
        { title: '> ', content: '专业安全研究团队长久积累，致力于区块链安全生态研究。' },
        { title: '> ', content: '良好的项目质量控制，对项目环节严谨把关，安全专家全程在线，实时解答用户问题。' },
      ],
      datalistx1: [
        {
          imageurl: require('@/assets/image/contentsvg/book.svg'),
          title: '合约故障',
          list: [
            '由于智能合约代码中可能存在的',
            '不合理故障处理机制',
            '造成的异常行为',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/setting.svg'),
          title: '拒绝服务',
          list: [
            '由于各种原因可能导致的',
            '拒绝服务风险',
            '&nbsp;',
          ],
        },
      ],
      datalistx3: [
        {
          imageurl: require('@/assets/image/contentsvg/book.svg'),
          title: '调用栈耗尽审核',
          list: [
            '检测栈高度限制',
            '是否出现栈耗尽情况',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/setting.svg'),
          title: '拒绝服务审核',
          list: [
            '过多请求导致合约拒绝服务',
            '&nbsp;',
          ],
        },
      ],
      datalistx: [
        {
          imageurl: require('@/assets/image/contentsvg/people.svg'),
          title: '隐私泄露',
          list: [
            '智能合约对区块链上的所有用户可见',
            '包括但不限于标记为 private 的资源',
            '或可造成隐私信息泄露',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/tag.svg'),
          title: '交易溢出与异常',
          list: [
            '由于智能合约本身的约束条件',
            '如条件竞争、交易顺序依赖等',
            '造成的交易溢出与异常',
          ],
        },
      ],
      datalistx2: [
        {
          imageurl: require('@/assets/image/contentsvg/people.svg'),
          title: '函数可见性审核',
          list: [
            '敏感函数继承权限检测',
            '函数调用权限检测',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/tag.svg'),
          title: '合约限制绕过审核',
          list: [
            '使合约失效，删除地址字节码',
            '&nbsp;',
          ],
        },
      ],
      title: '智能合约和审计',
      body:
          '智能合约审计就是仔细研究代码的过程，在这里就是指在把合约部署到区块链主网络中并使用之前发现错误、漏洞和风险；因为一旦发布，这些代码将无法再被修改。',
      image: require('@/assets/image/chain/basic/contract/contract.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/basic/contract/13_01.jpg'),
        require('@/assets/image/chain/basic/contract/13_02.jpg'),
        require('@/assets/image/chain/basic/contract/13_03.jpg'),
      ],
    };
  },
};
</script>

<style scoped>
</style>
